<!-- 外协订单组件 -->
<template>
  <div>
    <el-dialog
      width="40%"
      center
      :visible="visible"
      :lock-scroll="false"
      :destroy-on-close="true"
      custom-class="ele-dialog-form"
      :title="isUpdate?'空驶':'空驶'"
      @update:visible="updateVisible">

      <div style="margin-bottom: 20px;">
        <el-input
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 4}"
          placeholder="请输入空驶原因"
          v-model="cause"
          clearable>
        </el-input>
      </div>

      <div slot="footer">
        <el-button
          @click="updateVisible(false)">取消
        </el-button>
        <el-button
          style="margin-left: 20px;"
          type="primary"
          :loading="loading"
          @click="save">确定
        </el-button>
      </div>
    </el-dialog>

  </div>


</template>

<script>

import {order_unloaded} from "@/api/orderModule";

export default {
  name: 'UserEdit',
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  components: { },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,

      cause:'',

    };
  },

  mounted() {
    if (this.data) {
      console.log(this.data)
      this.form = Object.assign({}, this.data);
      this.isUpdate = true;
    } else {
      this.form = {};
      this.isUpdate = false;
    }
  },


  methods: {
    /* 保存编辑 */
    save() {
      // this.$refs['form'].validate((valid) => {
      //   if (valid) {
      //     this.loading = true;
      //     this.updateVisible(false);
      //     this.$emit('done');
      //     this.loading = false;
      //   } else {
      //     return false;
      //   }
      // });
      if(this.cause == ''){
        this.$message.error('请输入原因');
      }else {
        let data = {
          odd: this.data.order_id,
          cancel_reason: this.cause,
        }
        order_unloaded(this.data.id, data).then(res => {
          console.log(res)
          if (res.code == 200) {
            this.$message.success(res.msg);
            this.cause = '';
            this.updateVisible(false);
            this.$emit('done');
          } else {
            this.$message.error(res.msg);
          }
        }).catch(err => {
          this.$message.error(err.msg);
        });
      }
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },



  }
}
</script>

<style scoped lang="scss">
.no-multiple {
  // 隐藏多选
  ::v-deep .el-table__header-wrapper .el-checkbox {
    display: none;
  }
}
</style>
